import { createSlice } from "@reduxjs/toolkit"
import { API_GET_USER_DETAIL, API_GET_USER_LIST } from "src/constants"

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    userDetail: {},
    sidebarShow: true,
    userList: []
}

//Actions for User Management

//get user list
export const getUserList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_USER_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUserList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserList/fail',
            payload: {},
        }),
    },
})

//get user data from id
export const getUserById = (id) => ({
    type: 'API',
    payload: {
        url: API_GET_USER_DETAIL + id,
        method: 'GET',
        hideLoader: false,
        success: (data) => ({
            type: 'getUserDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserDetail/fail',
            payload: {},
        }),
    },
})

//Reducer For User
const UserSlice = createSlice({
    name: 'userSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getUserList/success', (state, action) => {
            state.userList = action.payload.data
        })
        builder.addCase('getUserList/fail', (state, action) => {
            state.userList = []
            state.isLoggedIn = false
        })
        builder.addCase('getUserDetail/success', (state, action) => {
            state.userDetail = action.payload.data
        })
        builder.addCase('getUserDetail/fail', (state, action) => {
            state.userDetail = {}
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = UserSlice.actions;
export default UserSlice.reducer