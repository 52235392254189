import { createSlice } from "@reduxjs/toolkit"
import { API_ADMIN_PROFILE, API_ADMIN_PROFILE_EDIT } from "src/constants"

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    profileDetail: {},
    sidebarShow: true,
}

//get profile data from id
export const getProfileById = (id) => ({
    type: 'API',
    payload: {
        url: API_ADMIN_PROFILE + id,
        method: 'GET',
        hideLoader: false,
        success: (data) => ({
            type: 'getProfileDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getProfileDetail/fail',
            payload: {},
        }),
    },
})

//update profile
export const profileEdit = (data) => ({
    type: 'API',
    payload: {
        url: API_ADMIN_PROFILE_EDIT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'profileEditAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'profileEditAction/fail',
            payload: {},
        }),
    },
})

//Reducer For Profile
const ProfileSlice = createSlice({
    name: 'profileSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getProfileDetail/success', (state, action) => {
            state.profileDetail = action.payload.data
        })
        builder.addCase('getProfileDetail/fail', (state, action) => {
            state.profileDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('profileEditAction/success', (state, action) => {
            state.profileDetail = action.payload.data
        })
        builder.addCase('profileEditAction/fail', (state, action) => {
            state.profileDetail = {}
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = ProfileSlice.actions;
export default ProfileSlice.reducer