
//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

export const setupAxios = (axios, store) => {

    let token = JSON.parse(localStorage.getItem('authTokendoozee'))
    let userData = JSON.parse(localStorage.getItem('userdoozee'))


    if (token) {
        store.dispatch({ type: 'login/success', payload: { data: userData } })
    } else {
        store.dispatch({ type: 'login/fail', payload: {} })
    }

    axios.interceptors.request.use(req => {
        const { auth: { userData } } = store.getState();

        if (userData && userData.accessToken) {
            req.headers["Authorization"] = `Bearer ${userData.accessToken}`;
        }

        return req;
    }, err => Promise.reject(err));

    axios.interceptors.response.use(null, (err) => {
        if (err.response) {
            if (err.response.status === 403) {
                store.dispatch({ type: 'login/fail' })

                return Promise.reject(err);
            } else return Promise.reject(err);
        } else if (err.request) {
            return Promise.reject({
                response: {
                    data: {
                        message: "Something went wrong, Please try again later!!!"
                    }
                }
            });
        }
    });
}

export const encrypt = param => {
    if (param)
        return btoa(param)
    else
        return ''
}
export const decrypt = param => {
    if (param)
        return atob(param)
    else
        return ''
}
export const debounce = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 500);
    };
};