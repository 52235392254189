// Env constant
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_API_IMAGE_BASE: API_IMAGE_BASE } = process.env;

// api constant
export const API_LOGIN = `${API_BASE}users/admin-login`;
export const API_CHANGE_PASSWORD = `${API_BASE}users/adminChangePassword`;

//Dashboard
export const API_DASHBOARD_LIST = `${API_BASE}userAnswer/dashboardDetails`;

//Profile Admin
export const API_ADMIN_PROFILE = `${API_BASE}users/getAdminDetails/`;
export const API_ADMIN_PROFILE_EDIT = `${API_BASE}users/updateUser`;

//CMS 
export const API_GET_CMS_DETAIL = `${API_BASE}cms/getCmsById`;
export const API_UPDATE_CMS_DETAIL = `${API_BASE}cms/updateCms`;

//Level
export const API_ADD_LEVEL = `${API_BASE}level/addLevel`
export const API_GET_LEVEL_LIST = `${API_BASE}level/totalLevelList`
export const API_UPDATE_LEVEL_LIST = `${API_BASE}level/updateLevel`
export const API_GET_LEVEL_ID_DETAIL = `${API_BASE}level/getLevelById/`
export const API_DELETE_LEVEL = `${API_BASE}level/deleteLevel/`
export const API_DELETE_QUESTION_BY_LEVEL = `${API_BASE}questions/removeQuestionFromLevel/`
export const API_NOT_ASSIGNED_QUESTION_LIST = `${API_BASE}questions/notAssignedQuestionList`

//User Management
export const API_GET_USER_LIST = `${API_BASE}users/getListOfUser`
export const API_GET_USER_DETAIL = `${API_BASE}users/getUserDetails/`

//Question Management
export const API_ADD_QUESTION = `${API_BASE}questions/addQuestion`
export const API_GET_QUESTION_LIST = `${API_BASE}questions/totalQuestionsList`
export const API_UPDATE_MULTIPLE_QUESTION = `${API_BASE}questions/updateMultipleQuestion`
export const API_GET_QUESTION_ID_DETAIL = `${API_BASE}questions/getQuestionById/`
export const API_QUESTION_BY_LEVEL = `${API_BASE}questions/questionsByLevelAdminPanel`
export const API_VERIFY_QUESTION_ANSWER = `${API_BASE}questions/verifyAnswer`
export const API_DELETE_QUESTION = `${API_BASE}questions/deleteQuestion/`
export const API_IMAGE_UPLOAD = `${API_BASE}common/S3upload`







