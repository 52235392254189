import { configureStore } from '@reduxjs/toolkit'
import apiMiddleware from '../middleware/api'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import LevelSlice from './LevelSlice'
import UserSlice from './UserSlice'
import QuestionSlice from './QuestionSlice'
import ProfileSlice from './ProfileSlice'
import DashboardSlice from './DashboardSlice'

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    level: LevelSlice,
    user: UserSlice,
    question: QuestionSlice,
    profile: ProfileSlice,
    dashboard: DashboardSlice
  },
  middleware: [thunk, apiMiddleware],
})
