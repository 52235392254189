import { createSlice } from "@reduxjs/toolkit";
import { API_ADD_QUESTION, API_DELETE_QUESTION, API_GET_QUESTION_ID_DETAIL, API_GET_QUESTION_LIST, API_IMAGE_UPLOAD, API_QUESTION_BY_LEVEL, API_UPDATE_MULTIPLE_QUESTION, API_VERIFY_QUESTION_ANSWER } from "src/constants";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    questionDetail: {},
    sidebarShow: true,
    questionList: [],
    questionbyLevel: []

}
//Actions For Question

//add Question
export const addQuestion = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_QUESTION,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addQuestion/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addQuestion/fail',
            payload: {},
        }),
    },
})

//get question list
export const getQuestionsList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_QUESTION_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getQuestionList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getQuestionList/fail',
            payload: {},
        }),
    },
})

//get question data from id
export const getQuestionById = (id) => ({
    type: 'API',
    payload: {
        url: API_GET_QUESTION_ID_DETAIL + id,
        method: 'GET',
        hideLoader: false,
        success: (data) => ({
            type: 'getQuestionDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getQuestionDetail/fail',
            payload: {},
        }),
    },
})

//Add Multiple questions in level
export const multipleQuestionAddInLevel = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_MULTIPLE_QUESTION,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'questionEditAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'questionEditAction/fail',
            payload: {},
        }),
    },
})

//delete question
export const questionDelete = (id) => ({
    type: 'API',
    payload: {
        url: API_DELETE_QUESTION + id,
        method: 'DELETE',
        hideLoader: false,
        success: (data) => ({
            type: 'deleteQuestion/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteQuestion/fail',
            payload: {},
        }),
    }
})

//verify question answer
export const verifyQuestionAnswer = (data) => ({
    type: 'API',
    payload: {
        url: API_VERIFY_QUESTION_ANSWER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'verifyAnswer/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'verifyAnswer/fail',
            payload: {},
        }),
    }
})

//question by level
export const questionByLevel = (data) => ({
    type: 'API',
    payload: {
        url: API_QUESTION_BY_LEVEL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'questionByLevel/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'questionByLevel/fail',
            payload: {},
        }),
    }
})

//Image Upload
export const imgUpload = (data) => ({
    type: 'API',
    payload: {
        url: API_IMAGE_UPLOAD,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'imgUpload/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'imgUpload/fail',
            payload: {},
        }),
    },
})



//Reducer For Question
const QuestionSlice = createSlice({
    name: 'questionSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getQuestionList/success', (state, action) => {
            state.questionList = action.payload.data
        })
        builder.addCase('getQuestionList/fail', (state, action) => {
            state.questionList = []
            state.isLoggedIn = false
        })
        builder.addCase('getQuestionDetail/success', (state, action) => {
            state.questionDetail = action.payload.data
        })
        builder.addCase('getQuestionDetail/fail', (state, action) => {
            state.questionDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('questionByLevel/success', (state, action) => {
            state.questionbyLevel = action.payload.data
        })
        builder.addCase('questionByLevel/fail', (state, action) => {
            state.questionbyLevel = []
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = QuestionSlice.actions;
export default QuestionSlice.reducer