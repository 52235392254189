import { createSlice } from "@reduxjs/toolkit"
import { API_DASHBOARD_LIST, API_DASHBOARD_USERS_DETAILS } from "src/constants"

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    userList: [],
    sidebarShow: true,
}

//get user list 
export const getAllUsersList = () => ({
    type: 'API',
    payload: {
        url: API_DASHBOARD_LIST,
        method: 'POST',
        hideLoader: false,
        success: (data) => ({
            type: 'getUserDetails/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserDetails/fail',
            payload: {},
        }),
    },
})

//Reducer For Profile
const DashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getUserDetails/success', (state, action) => {
            state.userList = action.payload.data
        })
        builder.addCase('getUserDetails/fail', (state, action) => {
            state.userList = {}
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = DashboardSlice.actions;
export default DashboardSlice.reducer