import { createSlice } from "@reduxjs/toolkit";
import { API_ADD_LEVEL, API_DELETE_LEVEL, API_DELETE_QUESTION_BY_LEVEL, API_GET_LEVEL_ID_DETAIL, API_GET_LEVEL_LIST, API_NOT_ASSIGNED_QUESTION_LIST, API_UPDATE_LEVEL_LIST } from "src/constants";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    levelDetail: {},
    sidebarShow: true,
    levelList: [],
    notAssignedQuestionList: []
}
//Actions For Level

//add Level
export const addLevel = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_LEVEL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addLevel/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addLevel/fail',
            payload: {},
        }),
    },
})

//get level list
export const getLevelList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_LEVEL_LIST,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getLevelList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getLevelList/fail',
            payload: {},
        }),
    },
})

//get level data from id
export const getLevelById = (id) => ({
    type: 'API',
    payload: {
        url: API_GET_LEVEL_ID_DETAIL + id,
        method: 'GET',
        hideLoader: false,
        success: (data) => ({
            type: 'getLevelDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getLevelDetail/fail',
            payload: {},
        }),
    },
})

//update level
export const levelEdit = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_LEVEL_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'levelEditAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'levelEditAction/fail',
            payload: {},
        }),
    },
})

//delete level
export const levelDelete = (id) => ({
    type: 'API',
    payload: {
        url: API_DELETE_LEVEL + id,
        method: 'DELETE',
        hideLoader: false,
        success: (data) => ({
            type: 'deleteLevel/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteLevel/fail',
            payload: {},
        }),
    }
})
//delete question in particular level
export const questionDeleteByLevel = (id) => ({
    type: 'API',
    payload: {
        url: API_DELETE_QUESTION_BY_LEVEL + id,
        method: 'POST',
        hideLoader: false,
        success: (data) => ({
            type: 'deleteQuestionByLevel/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteQuestionByLevel/fail',
            payload: {},
        }),
    }
})

//get Not Assigned Question List In Level
export const questionNotAssignedList = () => ({
    type: 'API',
    payload: {
        url: API_NOT_ASSIGNED_QUESTION_LIST,
        method: 'GET',
        hideLoader: false,
        success: (data) => ({
            type: 'questionNotAssigned/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'questionNotAssigned/fail',
            payload: {},
        }),
    }
})


//Reducer For Level
const LevelSlice = createSlice({
    name: 'levelSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getLevelList/success', (state, action) => {
            state.levelList = action.payload.data
        })
        builder.addCase('getLevelList/fail', (state, action) => {
            state.levelList = []
            state.isLoggedIn = false
        })
        builder.addCase('getLevelDetail/success', (state, action) => {
            state.levelDetail = action.payload.data
        })
        builder.addCase('getLevelDetail/fail', (state, action) => {
            state.levelDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('questionNotAssigned/success', (state, action) => {
            state.notAssignedQuestionList = action.payload.data
        })
        builder.addCase('questionNotAssigned/fail', (state, action) => {
            state.notAssignedQuestionList = []
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = LevelSlice.actions;
export default LevelSlice.reducer